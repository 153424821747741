import { useAuth } from './hooks/useAuth';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { SignInPage } from './views/pages/accounts/SignInPage';
import { SignUpPage } from './views/pages/accounts/SignUpPage';
import { HomePage } from './views/pages/home/HomePage';
import { ResetPassword } from './views/pages/accounts/ResetPassword';
import { VerifyResetPasswordPage } from './views/pages/accounts/VerifyResetPasswordPage';
import { CreatePatientPage } from './views/pages/patients/CreatePatientPage';
import { PatientDetailPage } from './views/pages/patients/PatientDetailPage';
import { PrivateRoute } from './views/components/common/PrivateRoute';
import { GuestLayout } from './views/layouts/GuestLayout';
import { AccountLayout } from './views/layouts/AccountLayout';
import { CreateOrganizationPage } from './views/pages/organizations/CreateOrganizationPage';
import { UpdateOrganizationPage } from './views/pages/organizations/UpdateOrganizationPage';
import { UpdatePatientPage } from './views/pages/patients/UpdatePatientPage';
import CheckScorePage from './views/pages/ohat/CheckScorePage';
import ScoreDetailPage from './views/pages/ohat/ScoreDetailPage';
import { Loading } from './views/components/common/Loading';
import { NotFoundPage } from './views/pages/errors/NotFoundPage';
import { SignOutPage } from './views/pages/accounts/SignOutPage';
import { OrganizationTypePage } from './views/pages/admin/OrganizationTypePage';
import { AdminLayout } from './views/layouts/AdminLayout';
import { PrivateAdminRoute } from './views/components/common/PrivateAdminRoute';
import { ErrorPage } from './views/pages/errors/ErrorPage';
import { OrganizationListPage } from './views/pages/admin/OrganizationListPage';

export function Routes() {
  const auth = useAuth();

  if (auth.isLoading) return <Loading />;

  const router = createBrowserRouter([
    {
      path: '/admin',
      element: <PrivateAdminRoute />,
      children: [
        {
          element: <AdminLayout />,
          children: [
            {
              index: true,
              element: <OrganizationListPage />,
            },
            {
              path: 'organizations/rate',
              element: <OrganizationTypePage />,
            },
          ],
        },
      ],
    },
    {
      path: '/',
      element: <PrivateRoute />,
      children: [
        {
          element: <AccountLayout />,
          children: [
            {
              index: true,
              element: <HomePage />,
            },
            {
              path: 'organizations/create',
              element: <CreateOrganizationPage />,
            },
            {
              path: 'organizations/:id/edit',
              element: <UpdateOrganizationPage />,
            },
            {
              path: 'patients/create',
              element: <CreatePatientPage />,
            },
            {
              path: 'patients/:id',
              element: <PatientDetailPage />,
            },
            {
              path: 'patients/:id/edit',
              element: <UpdatePatientPage />,
            },
            {
              path: 'patients/:id/check',
              element: <CheckScorePage isGuestMode={false} />,
            },
            {
              path: 'patients/:patientId/ohat-scores/:id',
              element: <ScoreDetailPage />,
            },
            // { path: "ohat-scores/pdf", element: <ScoreDetailPdfPage /> }, // 必要に応じてコメントアウトを外す
          ],
        },
      ],
    },
    {
      path: '/',
      element: <GuestLayout />,
      children: [
        { path: 'signin', element: <SignInPage /> },
        { path: 'signup', element: <SignUpPage /> },
        { path: 'signout', element: <SignOutPage /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify-password', element: <VerifyResetPasswordPage /> },
        { path: 'trial', element: <CheckScorePage isGuestMode={true} /> },
      ],
    },
    { path: '*', element: <NotFoundPage /> },
    { path: 'error', element: <ErrorPage /> },
  ]);

  return <RouterProvider router={router} />;
}
