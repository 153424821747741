type SortField = {
  key: string;
  order: number;
  type: 'asc' | 'desc';
};

type SortableObject = {
  [key: string]: any;
};

const compareValues = (a: any, b: any, type: 'asc' | 'desc' = 'asc') => {
  if (a === b) return 0;
  if (a === null || a === undefined) return type === 'asc' ? -1 : 1;
  if (b === null || b === undefined) return type === 'asc' ? 1 : -1;

  let comparison = 0;
  if (typeof a === 'string' && typeof b === 'string') {
    comparison = a.localeCompare(b);
  } else if (a instanceof Date && b instanceof Date) {
    comparison = a.getTime() - b.getTime();
  } else {
    comparison = a - b;
  }

  return type === 'asc' ? comparison : -comparison;
};

/**
 * 複数のフィールドで優先順位を付けてソートを行う関数
 * @param data ソート対象のデータ配列
 * @param sortFields ソートフィールドの配列 (優先順位付き)
 * @returns ソート済みの配列
 */
export const multiFieldSort = <T extends SortableObject>(
  data: T[],
  sortFields: SortField[],
): T[] => {
  return [...data].sort((a, b) => {
    // sortFieldsの順番(order)でソート
    const orderedFields = [...sortFields].sort((a, b) => a.order - b.order);
    for (const field of orderedFields) {
      const aValue = a[field.key];
      const bValue = b[field.key];
      const comparison = compareValues(aValue, bValue, field.type);
      if (comparison !== 0) {
        return comparison;
      }
    }

    return 0;
  });
};
