import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, InputRef, Space, Table, TableColumnType } from 'antd';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { useRef } from 'react';

export type Props = {
  organizationData: any[];
};
interface DataType {
  key: string;
  orgName: string;
  orgPhoneNo: string;
  accountName: string;
  accountEmail: string;
}

type DataIndex = keyof DataType;

export const OrganizationsTable = (props: Props) => {
  const { organizationData } = props;
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (confirm: FilterDropdownProps['confirm']) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
  ): TableColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(confirm)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            検索
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
            }}
            size="small"
            style={{ width: 90 }}
          >
            リセット
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              handleSearch(confirm);
              close();
            }}
          >
            閉じる
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const reportColumns = [
    {
      title: '施設名',
      dataIndex: 'orgName',
      key: 'orgName',
      ...getColumnSearchProps('orgName'),
    },
    {
      title: '機関種別',
      dataIndex: 'orgType',
      key: 'orgType',
    },
    {
      title: '都道府県',
      dataIndex: 'orgPrefecture',
      key: 'orgPrefecture',
    },
    {
      title: '電話番号',
      dataIndex: 'orgPhoneNo',
      key: 'orgPhoneNo',
      ...getColumnSearchProps('orgPhoneNo'),
    },
    {
      title: '担当者名',
      dataIndex: 'accountName',
      key: 'accountName',
      ...getColumnSearchProps('accountName'),
    },
    {
      title: 'メールアドレス',
      dataIndex: 'accountEmail',
      key: 'accountEmail',
      ...getColumnSearchProps('accountEmail'),
    },
    {
      title: '患者数',
      dataIndex: 'patientsCount',
      key: 'patientsCount',
      // defaultSortOrder: 'descend',
      // sorter: (a: any, b: any) =>
      //   parseInt(a.patientsCount) - parseInt(b.patientsCount),
    },
    {
      title: '登録日',
      dataIndex: 'registeredAt',
      key: 'registeredAt',
    },
    {
      title: '最終アクセス日',
      dataIndex: 'lastActivityAt',
      key: 'lastActivityAt',
    },
  ];

  return (
    <Table
      dataSource={organizationData}
      columns={reportColumns}
      size="small"
      bordered
      pagination={{
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'],
      }}
      scroll={{ x: '90%' }}
      style={{ fontSize: 12 }}
    />
  );
};
