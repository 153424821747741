import { BaseConstants } from './baseConstants';
// 歯科介入
export class DentalIntervention extends BaseConstants {
  static readonly none = new DentalIntervention('none', '歯科介入 なし');
  static readonly inProgress = new DentalIntervention(
    'inProgress',
    '歯科介入 あり',
  );
  static readonly underConsideration = new DentalIntervention(
    'underConsideration',
    '歯科介入 検討中',
  );

  private constructor(
    public readonly key: string,
    public readonly label: string,
  ) {
    super();
  }

  toString() {
    return this.key;
  }
}
