import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Tag, Space, Typography, Avatar, List } from 'antd';
import { getFirstChar } from '../../../utils/string';
import { Gender } from '../../../constants/gender';
import { PatientCareLevel } from '../../../constants/careLevel';
import { IndependentLifeLevel } from '../../../constants/independentLifeLevel';
import { DentalIntervention } from '../../../constants/dentalIntervention';
import { formatYYYYMMDDSlash } from '../../../utils/date';

type PatientData = {
  key: string;
  id: string;
  name: string;
  age: number | undefined;
  gender: string;
  careLevel: string;
  lifeLevel: string;
  dentalIntervention: string;
  latestOhatScoreEvaluatedAt: string | undefined;
  ohatScoreEvaluatedCount: number;
};

export type Props = {
  patients: PatientData[];
};

export const PatientList: React.FC<Props> = ({ patients }) => {
  const navigate = useNavigate();

  const onClick = (patientId: string) => {
    navigate(`/patients/${patientId}`);
  };

  return (
    <List
      itemLayout="vertical"
      size="large"
      pagination={{
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20', '30', '50'],
        defaultPageSize: 20,
        locale: {
          items_per_page: '件表示',
          jump_to: 'ページへ',
        },
      }}
      dataSource={patients}
      renderItem={(item) => (
        <List.Item
          key={item.id}
          onClick={() => onClick(item.id)}
          className="hoverEffect"
        >
          <List.Item.Meta
            avatar={<Avatar>{getFirstChar(item.name)} </Avatar>}
            title={
              <Typography.Text
                style={{
                  fontSize: 18,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {item.name}
                <Typography.Text
                  style={{
                    fontSize: 12,
                    color: 'gray',
                    fontWeight: 'normal',
                  }}
                >
                  {`${
                    item.latestOhatScoreEvaluatedAt == null
                      ? '未評価'
                      : `評価回数: ${
                          item.ohatScoreEvaluatedCount
                        }回　最終評価: ${formatYYYYMMDDSlash(
                          item.latestOhatScoreEvaluatedAt,
                        )}`
                  }`}
                </Typography.Text>
              </Typography.Text>
            }
            description={
              <Space size={[0, 8]} wrap>
                <Tag
                  color={item.gender === Gender.female.key ? 'red' : 'blue'}
                >{`${Gender.getByKey(item.gender).label}`}</Tag>
                {item.age && item.age !== 0 ? (
                  <Tag color="default">{`${item.age}歳`}</Tag>
                ) : null}
                {PatientCareLevel.getByKey(item.careLevel) !==
                  PatientCareLevel.none && (
                  <Tag color="volcano">{`${
                    PatientCareLevel.getByKey(item.careLevel).label
                  }`}</Tag>
                )}
                {IndependentLifeLevel.getByKey(item.lifeLevel) !==
                  IndependentLifeLevel.none && (
                  <Tag color="geekblue">{`${
                    IndependentLifeLevel.getByKey(item.lifeLevel).label
                  }`}</Tag>
                )}
                {DentalIntervention.getByKey(item.dentalIntervention) !==
                  DentalIntervention.none && (
                  <Tag color="blue">{`${
                    DentalIntervention.getByKey(item.dentalIntervention).label
                  }`}</Tag>
                )}
              </Space>
            }
            style={{ marginBottom: 0 }}
          />
        </List.Item>
      )}
    />
  );
};
