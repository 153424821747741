import React, { useState, useMemo, useEffect } from 'react';
import { Divider } from 'antd';
import { Patient } from '@models/patient';
import { PatientSearchForm } from './form/PatientSearchForm';
import { PatientList } from './PatientList';
import { multiFieldSort } from '../../../utils/sort';
import { PatientCareLevel } from '../../../constants/careLevel';
import { IndependentLifeLevel } from '../../../constants/independentLifeLevel';

type SearchFormValues = {
  name?: string;
  gender?: string;
  careLevel?: string;
  lifeLevel?: string;
  dentalIntervention?: string;
  evaluationDate?: any;
  ascSort?: { key: string; order: number }[];
  descSort?: { key: string; order: number }[];
};

export type Props = {
  patients: Patient[];
  showSearchForm: boolean;
  setShowSearchForm: (show: boolean) => void;
};

export const PatientListPanel: React.FC<Props> = ({
  patients,
  showSearchForm,
  setShowSearchForm,
}) => {
  const [filteredAndSortedPatients, setFilteredAndSortedPatients] = useState<
    Patient[]
  >([]);

  useEffect(() => {
    setFilteredAndSortedPatients(patients);
  }, [patients]);

  const handleSearch = (values: SearchFormValues) => {
    let filtered = patients.filter((patient) => {
      return (
        (!values.name || patient.name.includes(values.name)) &&
        (!values.gender || patient.gender === values.gender) &&
        (!values.careLevel || patient.careLevel === values.careLevel) &&
        (!values.lifeLevel ||
          patient.independentLifeLevel === values.lifeLevel) &&
        (!values.dentalIntervention ||
          patient.dentalIntervention === values.dentalIntervention)
      );
    });
    filtered = filtered.map((patient, index) => ({
      ...patient,
      latestOhatScoreEvaluatedAtForSort:
        patient.latestOhatScoreEvaluatedAt?.split('T')[0],
      careLevelForSort: PatientCareLevel.getByKey(patient.careLevel).sortOrder,
      independentLifeLevelForSort: IndependentLifeLevel.getByKey(
        patient.independentLifeLevel,
      ).sortOrder,
    }));

    // ソート条件を取得し、優先度順に並べ替え
    const ascSortFields =
      values.ascSort?.map((v) => ({ ...v, type: 'asc' as const })) || [];
    const descSortFields =
      values.descSort?.map((v) => ({ ...v, type: 'desc' as const })) || [];
    const sortFields = [...ascSortFields, ...descSortFields].sort(
      (a, b) => a.order - b.order,
    );
    const sorted = multiFieldSort(filtered, sortFields);
    setFilteredAndSortedPatients(sorted);
  };

  const resetSearch = () => {
    setFilteredAndSortedPatients(patients);
  };

  const patientData = useMemo(() => {
    return filteredAndSortedPatients.map((v) => ({
      key: v.id,
      id: v.id,
      name: v.name,
      age: v.age,
      gender: v.gender,
      careLevel: v.careLevel,
      lifeLevel: v.independentLifeLevel,
      dentalIntervention: v.dentalIntervention,
      latestOhatScoreEvaluatedAt: v.latestOhatScoreEvaluatedAt,
      ohatScoreEvaluatedCount: v.ohatScoreEvaluatedCount ?? 0,
    }));
  }, [filteredAndSortedPatients]);

  return (
    <>
      <div style={{ overflow: 'hidden' }}>
        {showSearchForm && (
          <div
            style={{
              animation: showSearchForm
                ? 'slideDown 0.3s ease-out'
                : 'slideUp 0.3s ease-out',
              transformOrigin: 'top',
            }}
          >
            <PatientSearchForm
              onSearch={handleSearch}
              onReset={resetSearch}
              onClose={() => setShowSearchForm(false)}
            />
            <Divider />
          </div>
        )}
        <style>
          {`
            @keyframes slideDown {
              from {
                transform: scaleY(0);
                opacity: 0;
              }
              to {
                transform: scaleY(1);
                opacity: 1;
              }
            }
            @keyframes slideUp {
              from {
                transform: scaleY(1);
                opacity: 1;
              }
              to {
                transform: scaleY(0);
                opacity: 0;
              }
            }
          `}
        </style>
      </div>
      <PatientList patients={patientData} />
    </>
  );
};
