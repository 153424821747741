import React from 'react';
import { Form, Input, Select, Button, Space, Tag } from 'antd';
import {
  SearchOutlined,
  ReloadOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { Gender } from '../../../../constants/gender';
import { PatientCareLevel } from '../../../../constants/careLevel';
import { IndependentLifeLevel } from '../../../../constants/independentLifeLevel';
import { DentalIntervention } from '../../../../constants/dentalIntervention';

const { Option } = Select;

export type SearchFormValues = {
  name?: string;
  gender?: string;
  careLevel?: string;
  lifeLevel?: string;
  dentalIntervention?: string;
  evaluationDate?: any;
  ascSort?: { key: string; order: number }[];
  descSort?: { key: string; order: number }[];
};

type SearchFormProps = {
  onSearch: (values: SearchFormValues) => void;
  onReset: () => void;
  onClose: () => void;
};

const sortFields = [
  { key: 'name', label: '名前' },
  { key: 'age', label: '年齢' },
  { key: 'careLevelForSort', label: '要介護度' },
  { key: 'independentLifeLevelForSort', label: '自立度' },
  { key: 'latestOhatScoreEvaluatedAtForSort', label: '最終評価日' },
  { key: 'ohatScoreEvaluatedCount', label: '評価回数' },
];

export const PatientSearchForm: React.FC<SearchFormProps> = ({
  onSearch,
  onReset,
  onClose,
}) => {
  const [form] = Form.useForm();

  const resetSearch = () => {
    form.resetFields();
    onReset();
  };

  const handleSortChange = (value: string[], field: 'ascSort' | 'descSort') => {
    const otherField = field === 'ascSort' ? 'descSort' : 'ascSort';
    const currentValues = form.getFieldValue(field) || [];
    const otherValues = form.getFieldValue(otherField) || [];

    // 現在の順序を保持
    const existingOrders = [...currentValues, ...otherValues]
      .sort((a, b) => a.order - b.order)
      .map((item) => ({
        key: item.key,
        order: item.order,
      }));

    // 他方フィールドから重複項目を削除
    const updatedOtherValues = otherValues.filter(
      (item: { key: string }) => !value.includes(item.key),
    );

    // 現在のフィールドにセットされた値を更新
    const updatedCurrentValues = value.map((key) => {
      // 既存の順序を探す
      const existingOrder = existingOrders.find(
        (item) => item.key === key,
      )?.order;
      const existingItem = currentValues.find(
        (item: { key: string }) => item.key === key,
      );

      return (
        existingItem || {
          key,
          order:
            existingOrder ||
            Math.max(...existingOrders.map((item) => item.order || 0), 0) + 1,
        }
      );
    });

    // 全てのソート項目を結合して順序を保持しつつ新しい項目を末尾に追加
    const allSortValues = [...updatedOtherValues, ...updatedCurrentValues].sort(
      (a, b) => (a.order || Infinity) - (b.order || Infinity),
    );

    // orderを1から振り直す
    const recalculatedSortValues = allSortValues.map((item, index) => ({
      ...item,
      order: index + 1,
    }));

    // 再計算された値を現在のフィールドと他方のフィールドに振り分ける
    const recalculatedCurrentValues = recalculatedSortValues.filter((item) =>
      value.includes(item.key),
    );
    const recalculatedOtherValues = recalculatedSortValues.filter(
      (item) => !value.includes(item.key),
    );

    // フォームの値を更新
    form.setFieldsValue({
      [field]: recalculatedCurrentValues,
      [otherField]: recalculatedOtherValues,
    });
  };

  const handleSubmit = (values: any) => {
    // フォームの値を整形して onSearch に渡す
    const formattedValues = {
      ...values,
      ascSort: values.ascSort || [],
      descSort: values.descSort || [],
    };
    onSearch(formattedValues);
  };

  return (
    <Form form={form} onFinish={handleSubmit} layout="vertical">
      <div
        style={{
          border: '1px solid #f0f0f0',
          borderRadius: '8px',
          padding: '12px',
          marginBottom: '16px',
          backgroundColor: '#fafafa',
        }}
      >
        <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: '16px' }}>
          <Form.Item name="name" label="名前">
            <Input placeholder="患者名を入力" style={{ width: 200 }} />
          </Form.Item>
          <Form.Item name="gender" label="性別">
            <Select placeholder="性別を選択" style={{ width: 120 }}>
              <Option value={Gender.male.key}>{Gender.male.label}</Option>
              <Option value={Gender.female.key}>{Gender.female.label}</Option>
            </Select>
          </Form.Item>
          <Form.Item name="careLevel" label="要介護度">
            <Select placeholder="要介護度を選択" style={{ width: 160 }}>
              {Object.values(PatientCareLevel).map((level) => (
                <Option key={level.key} value={level.key}>
                  {level.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="lifeLevel" label="自立度">
            <Select placeholder="自立度を選択" style={{ width: 200 }}>
              {Object.values(IndependentLifeLevel).map((level) => (
                <Option key={level.key} value={level.key}>
                  {level.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="dentalIntervention" label="歯科介入">
            <Select placeholder="歯科介入を選択" style={{ width: 160 }}>
              {Object.values(DentalIntervention).map((intervention) => (
                <Option key={intervention.key} value={intervention.key}>
                  {intervention.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </div>

      <div
        style={{
          border: '1px solid #f0f0f0',
          borderRadius: '8px',
          padding: '12px',
          marginBottom: '16px',
          backgroundColor: '#fafafa',
        }}
      >
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
          <Form.Item name="ascSort" label="昇順ソート" style={{ width: '40%' }}>
            <Select
              mode="multiple"
              placeholder="昇順ソート項目を選択"
              tagRender={tagRender}
              style={{ width: '100%' }}
              onChange={(value) => handleSortChange(value, 'ascSort')}
            >
              {sortFields.map((field) => (
                <Option key={field.key} value={field.key}>
                  {field.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="descSort"
            label="降順ソート"
            style={{ width: '40%' }}
          >
            <Select
              mode="multiple"
              placeholder="降順ソート項目を選択"
              tagRender={tagRender}
              style={{ width: '100%' }}
              onChange={(value) => handleSortChange(value, 'descSort')}
            >
              {sortFields.map((field) => (
                <Option key={field.key} value={field.key}>
                  {field.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </div>
      <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Space>
          <Button
            onClick={() => {
              resetSearch();
              onClose();
            }}
            icon={<CloseOutlined />}
          >
            閉じる
          </Button>
          <Button onClick={resetSearch} icon={<ReloadOutlined />}>
            リセット
          </Button>
          <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
            検索
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

const tagRender = (props: any) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color="blue"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  );
};
