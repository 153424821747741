import { BaseConstants } from './baseConstants';

export class IndependentLifeLevel extends BaseConstants {
  static readonly none = new IndependentLifeLevel('none', 'なし', 0);
  static readonly rankJ = new IndependentLifeLevel('j', '生活自立 ランクJ', 1);
  static readonly rankA = new IndependentLifeLevel(
    'a',
    '準寝たきり ランクA',
    2,
  );
  static readonly rankB = new IndependentLifeLevel('b', '寝たきり ランクB', 3);
  static readonly rankC = new IndependentLifeLevel('c', '寝たきり ランクC', 4);

  private constructor(
    public readonly key: string,
    public readonly label: string,
    public readonly sortOrder: number,
  ) {
    super();
  }

  toString() {
    return this.key;
  }
}
